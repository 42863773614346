<template>
  <page-header-wrapper style="margin: unset;">
    <a-row :gutter='24'>
      <a-col :sm='12' :md='8' :xl='8' :style="{ marginBottom: '24px' }">
<!--        <a-card>-->
<!--          <div class="card_title">本月商品折扣-->
<!--            <span style="margin-left: 40px;">总数：-{{dashboard.lastUsedWealthQuotaAmountTotal}}</span>-->
<!--          </div>-->
<!--          <div class="card_title" style="line-height: 36px;">云店使用：-{{dashboard.lastUsedWealthStore}} <span style="width: 40px;"></span> 31修改使用：-{{dashboard.lastUsedWealthUpdate3}} <span style="width: 40px;"></span> 用户代用：-{{dashboard.lastUsedWealthNormal}}</div>-->
<!--          <div class="card_title" style="line-height: 36px;">服务使用：-{{dashboard.lastUsedWealthCenter}} <span style="width: 40px;"></span> 41修改使用：-{{dashboard.lastUsedWealthUpdate4}}</div>-->
<!--          <div class="card_title" style="line-height: 36px;">运营使用：-{{dashboard.lastUsedWealthPresident}} <span style="width: 40px;"></span> 51修改使用：-{{dashboard.lastUsedWealthUpdate5}}</div>-->
<!--        </a-card>-->
        <a-tabs default-active-key="1" :tabBarStyle="{background: '#fff'}" @change="getDataByMonth">
          <a-tab-pane key="1" :tab="'本月'">
            <a-card>
              <div class="card_title">本月商品折扣
                <span style="margin-left: 40px;">总数：-{{dashboard.lastUsedWealthQuotaAmountTotal}} (31:{{dashboard.three}}  &nbsp;  41:{{dashboard.four}}   &nbsp;  51:{{dashboard.five}})</span>
              </div>
              <div class="card_title" style="line-height: 36px;">云店使用：-{{dashboard.lastUsedWealthStore}} <span style="width: 40px;"></span> 31修改使用：-{{dashboard.lastUsedWealthUpdate3}} <span style="width: 40px;"></span> 用户代用：-{{dashboard.lastUsedWealthNormal}}</div>
              <div class="card_title" style="line-height: 36px;">服务使用：-{{dashboard.lastUsedWealthCenter}} <span style="width: 40px;"></span> 41修改使用：-{{dashboard.lastUsedWealthUpdate4}}</div>
              <div class="card_title" style="line-height: 36px;">运营使用：-{{dashboard.lastUsedWealthPresident}} <span style="width: 40px;"></span> 51修改使用：-{{dashboard.lastUsedWealthUpdate5}}</div>
            </a-card>
          </a-tab-pane>
          <a-tab-pane key="2" :tab="'上月'">
            <a-card>
              <div class="card_title">上月商品折扣
                <span style="margin-left: 40px;">总数：-{{lastDashboard.lastUsedWealthQuotaAmountTotal}}(31:{{lastDashboard.three}}  &nbsp;  41:{{lastDashboard.four}}   &nbsp;  51:{{lastDashboard.five}})</span>
              </div>
              <div class="card_title" style="line-height: 36px;">云店使用：-{{lastDashboard.lastUsedWealthStore}} <span style="width: 40px;"></span> 31修改使用：-{{lastDashboard.lastUsedWealthUpdate3}} <span style="width: 40px;"></span> 用户代用：-{{lastDashboard.lastUsedWealthNormal}}</div>
              <div class="card_title" style="line-height: 36px;">服务使用：-{{lastDashboard.lastUsedWealthCenter}} <span style="width: 40px;"></span> 41修改使用：-{{lastDashboard.lastUsedWealthUpdate4}}</div>
              <div class="card_title" style="line-height: 36px;">运营使用：-{{lastDashboard.lastUsedWealthPresident}} <span style="width: 40px;"></span> 51修改使用：-{{lastDashboard.lastUsedWealthUpdate5}}</div>
            </a-card>
          </a-tab-pane>
        </a-tabs>
      </a-col>
      <!-- <a-col :sm='12' :md='8' :xl='8' :style="{ marginBottom: '24px' }">
        <a-card>
          <div class="card_title">本月商品折扣 总数：-{{dashboard.lastUsedWealthQuotaAmountTotal}}</div>
          <div class="card_title" style="line-height: 30px;">31：商户使用：-{{dashboard.lastUsedWealthBtcfQuotaAmountMerchant}}  用户使用：-{{dashboard.lastUsedWealthBtcfQuotaAmountMember}}  合计：-{{dashboard.lastUsedWealthBtcfQuotaAmount}}</div>
          <div class="card_title" style="line-height: 30px;">41：商户使用：-{{dashboard.lastUsedWealthQuotaAmountMerchant}}  用户使用：-{{dashboard.lastUsedWealthQuotaAmountMember}}  合计：-{{dashboard.lastUsedWealthQuotaAmount}}</div>
          <div class="card_title" style="line-height: 30px;">51：商户使用：-{{dashboard.lastUsedWwealthZxQuotaAmountMerchant}}</div>
        </a-card>
      </a-col>
      <a-col :sm='12' :md='8' :xl='8' :style="{ marginBottom: '24px' }">
        <a-card>
          <div class="card_title" style="line-height: 36px;">云店使用：-{{dashboard.lastUsedWealthStore}} <span style="width: 40px;"></span> 用户代用：-{{dashboard.lastUsedWealthNormal}}</div>
          <div class="card_title" style="line-height: 36px;">服务使用：-{{dashboard.lastUsedWealthCenter}} <span style="width: 40px;"></span> 修改使用：-{{dashboard.lastUsedWealthUpdate}}</div>
          <div class="card_title" style="line-height: 36px;">运营使用：-{{dashboard.lastUsedWealthPresident}}</div>
        </a-card>
      </a-col> -->
    </a-row>
    <a-form-model ref='form' :model='form' layout='inline'>
      <a-form-model-item prop='quotaType'>
        <a-select v-model="form.quotaType" placeholder="名额类型" style="width: 250px">
          <a-select-option :value="undefined">
            名额类型
          </a-select-option>
          <a-select-option :value="3">
            31
          </a-select-option>
          <a-select-option :value="1">
            41
          </a-select-option>
          <a-select-option :value="2">
            51
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item prop='memberType'>
        <a-select v-model="form.memberType" placeholder="身份类型" style="width: 250px">
          <a-select-option value="2">
            全部会员
          </a-select-option>
          <a-select-option value="1">
            会员
          </a-select-option>
          <a-select-option value="3">
            云店
          </a-select-option>
          <a-select-option value="4">
            服务中心
          </a-select-option>
          <a-select-option value="5">
            运营总裁
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item prop='type'>
        <a-select v-model="form.type" placeholder="记录类型" style="width: 250px">
          <a-select-option :value="undefined">
            记录类型
          </a-select-option>
          <a-select-option :value="2">
            使用
          </a-select-option>
          <a-select-option :value="3">
            修改
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item prop='endTag'>
        <a-select v-model="form.endTag" placeholder="订单尾号" style="width: 250px">
          <a-select-option value="-1">
            全部
          </a-select-option>
          <a-select-option v-for="i in 7" :key="i" :value="i">
            {{i}}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item prop='keyword'>
        <a-input v-model='form.keyword' style="width: 300px" placeholder="姓名/手机号" />
      </a-form-model-item>
      <!-- <a-form-model-item prop='start'>
        <a-range-picker
          v-model="dateArr"
          :format="[formatType, formatType]"
          :show-time="{
            hideDisabledOptions: true
          }"
          :disabled-date="disabledDate"
          @change="dateChange"
        />
      </a-form-model-item> -->
      <a-form-model-item>
        <a-button type='primary' @click='handleSubmit'>
          查询
        </a-button>
      </a-form-model-item>
      <a-form-model-item>
        <a-button @click='handleReset'>
          重置
        </a-button>
      </a-form-model-item>
    </a-form-model>
    <div>
      <div class='table-operator' style="margin-top: 20px">
        <a-button type='primary' icon='reload' @click='$refs.table.refresh(true)'>刷新</a-button>
        <span style="margin-left: 10px;margin-right: 10px;"> 笔数：{{this.total}}</span>
        <span> 变动值：{{this.ext4}}</span>
      </div>

      <s-table id="table" ref='table' rowKey='id' size='default' :columns='columns' :scroll='{ x: 1500}' :data='loadData'>
        <span slot='quotaType' slot-scope='text'>
          <template>
            {{ text == 1 ? "41" : text == 2 ? "51" : text == 3 ? "31" :  "" }}
          </template>
        </span>
        <span slot='memberType' slot-scope='text'>
          <template>
            {{ getUserTypeName(text) }}
          </template>
        </span>
        <span slot='orderId' slot-scope='text, record'>
          <template>
          {{record.business===3?record.opUsername:record.orderId}}
          </template>
        </span>
      </s-table>
    </div>
  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis, ChartCard } from '@/components'
import { getAuditOrderData, queryDiscount } from '@/api/member-order'
import moment from 'moment'
import { getUserTypeName } from '@/utils/util'


const columns = [
  {
    width: 100,
    title: '名额类型',
    dataIndex: 'quotaType',
    scopedSlots: { customRender: 'quotaType' }
  },
  {
    width: 100,
    title: '变动类型',
    dataIndex: 'businessName',
    scopedSlots: { customRender: 'businessName' }
  },
  {
    width: 120,
    title: '变动值',
    dataIndex: 'businessContent',
    scopedSlots: { customRender: 'businessContent' }
  },
  {
    width: 100,
    title: '变动后数值',
    dataIndex: 'afterAmount',
    scopedSlots: { customRender: 'afterAmount' }
  },
  {
    width: 100,
    title: '身份类型',
    dataIndex: 'memberType',
    scopedSlots: { customRender: 'memberType' }
  },
  {
    width: 200,
    title: '发生订单号',
    dataIndex: 'orderId',
    scopedSlots: { customRender: 'orderId' }
  },
  {
    width: 200,
    title: '时间',
    dataIndex: 'createTime',
    scopedSlots: { customRender: 'createTime' }
  },
    {
    width: 180,
    title: '备注',
    dataIndex: 'orderTypeName',
    scopedSlots: { customRender: 'orderTypeName' }
  },
    {
    width: 150,
    title: '姓名',
    dataIndex: 'userName',
    scopedSlots: { customRender: 'userName' }
  },
  {
    width: 150,
    title: '手机号',
    dataIndex: 'mobile',
    scopedSlots: { customRender: 'mobile' }
  },
]

const formatType = 'YYYY-MM-DD';
const baseStart = moment().startOf('month').format(formatType);
const baseEnd = moment().format(formatType);

export default {
  name: 'MonthDiscountUseList',
  components: {
    STable, Ellipsis, ChartCard
  },
  data() {
    return {
      // 筛选表单
      formatType,
      dateArr: [baseStart, baseEnd],
      form: {
        keyword: "",
        start: moment(baseStart).startOf('day').format(formatType + " HH:mm"),
        end: moment(baseEnd).endOf('day').format(formatType+ " HH:mm"),
      },
      loading: true,
      dashboard: {}, //订单统计数据
      lastDashboard: {}, //上月订单统计数据
      // 表头
      columns,
      // 加载数据方法 必须为 Promise 对象
      queryParam: {
        business: 2
      },
      total:0,
      ext4:0,
      loadData: parameter => {
        parameter.pageNum = parameter.current;
        parameter.pageSize = parameter.size;
        return queryDiscount(Object.assign(parameter, this.queryParam, this.form))
          .then(datum => {
            this.total = datum.total;
            this.ext4 = datum.ext4;
            return datum
          })
      },
    }
  },
  created() {
    this.getOrderData();
  },
  methods: {
    moment,
    getUserTypeName,
    disabledDate(current) {
      return current && current > moment().endOf('day');
    },
    dateChange() {
      this.form.start =  moment(this.dateArr[0]).startOf('day').format(this.formatType + " HH:mm");
      this.form.end =  moment(this.dateArr[1]).endOf('day').format(this.formatType+ " HH:mm");
    },
    handleReset() {
      this.dateArr = [baseStart, baseEnd];
      this.form.start = moment(baseStart).startOf('day').format(this.formatType + " HH:mm");
      this.form.end = moment(baseEnd).endOf('day').format(this.formatType + " HH:mm");
      this.$refs.form.resetFields()
      this.$refs.table.refresh(true)
    },
    handleSubmit() {
      if(this.form.endTag === "-1"){
        delete this.form.endTag;
      }
      this.getOrderData();
      this.$refs.table.refresh(true)
    },
    getOrderData() {
      getAuditOrderData(Object.assign({}, this.form)).then(result => {
        result.lastWealthQuotaAmountTotal = result.lastWealthBtcfQuotaAmountMerchant + result.lastWealthBtcfQuotaAmountMember + result.lastWealthQuotaAmountMerchant + result.lastWealthQuotaAmountMember + result.lastWwealthZxQuotaAmountMerchant + result.lastWwealthZxQuotaAmountMember;
        result.lastWealthQuotaAmountTotal = parseFloat(result.lastWealthQuotaAmountTotal.toFixed(2));
        this.$emit("setGetTitle", result.lastWealthQuotaAmountTotal);
        result.lastUsedWealthQuotaAmountTotal = result.lastUsedWealthBtcfQuotaAmount + result.lastUsedWealthQuotaAmount + result.lastUsedWwealthZxQuotaAmount;
        result.lastUsedWealthQuotaAmountTotal = parseFloat(result.lastUsedWealthQuotaAmountTotal.toFixed(2));
        this.$emit("setUseTitle", result.lastUsedWealthQuotaAmountTotal);
        this.dashboard = result;
        this.loading = false;

        result.three = result.lastUsedWealthBtcfQuotaAmount
        result.four = result.lastUsedWealthQuotaAmount
        result.five = result.lastUsedWwealthZxQuotaAmount
      })

      //上月
      let lastMonth = this.getLastMonth();
      getAuditOrderData(Object.assign({month:lastMonth}, this.form)).then(result => {
        result.lastWealthQuotaAmountTotal = result.lastWealthBtcfQuotaAmountMerchant + result.lastWealthBtcfQuotaAmountMember + result.lastWealthQuotaAmountMerchant + result.lastWealthQuotaAmountMember + result.lastWwealthZxQuotaAmountMerchant + result.lastWwealthZxQuotaAmountMember;
        result.lastWealthQuotaAmountTotal = parseFloat(result.lastWealthQuotaAmountTotal.toFixed(2));

        result.lastUsedWealthQuotaAmountTotal = result.lastUsedWealthBtcfQuotaAmount + result.lastUsedWealthQuotaAmount + result.lastUsedWwealthZxQuotaAmount;
        result.lastUsedWealthQuotaAmountTotal = parseFloat(result.lastUsedWealthQuotaAmountTotal.toFixed(2));

        result.three = result.lastUsedWealthBtcfQuotaAmount
        result.four = result.lastUsedWealthQuotaAmount
        result.five = result.lastUsedWwealthZxQuotaAmount
        this.lastDashboard = result;
        this.loading = false;
      })
    },
    getLastMonth() {
      let year, lastMonth;
      let date = new Date();
      let nowYear = date.getFullYear();   //当前年：四位数字
      let nowMonth = date.getMonth();     //当前月：0-11
      if (nowMonth === 0) {   //如果是0，则说明是1月份，上一个月就是去年的12月
        year = nowYear - 1;
        lastMonth = 12;
      } else { //不是1月份，年份为当前年，月份本来是要减1的，但是由于`getMonth()`的月份本身就是少了1的，所以月份不用变。
        year = nowYear;
        lastMonth = nowMonth;
      }
      lastMonth = lastMonth < 10 ? ('0' + lastMonth) : lastMonth;   //月份格式化：月份小于10则追加个0
      return year + '-' + lastMonth;
    },
    getMonthFinalDay(year,month){
      let day='';
      if(year == null || year===''){
        year = new Date().getFullYear();
      }
      if(month == null || month===''){
        month = new Date().getMonth()+1;
      }
      day = new Date(new Date(year,month).setDate(0)).getDate();
      return year+"-"+month+"-"+day;

    },
    getDataByMonth(tabs){
      let start = "";
      let end = "";
      if(tabs === "1"){
        //本月
        let year = new Date().getFullYear();
        let month = new Date().getMonth()+1;
        end = this.getMonthFinalDay(year,month);
        start = year + "-" + month + "-" + "01";
      }else{
        //上月
        let lastMonth = this.getLastMonth();
        let monthYear = lastMonth.split("-");
        let year = monthYear[0];
        let month = monthYear[1];
        end = this.getMonthFinalDay(year,month);
        start = year + "-" + month + "-" + "01";
      }
      end = end + " 23:59";
      start = start + " 00:00";
      console.log(start + " ## " +end);
      this.form.start = start;
      this.form.end = end;
      this.handleSubmit();

    }
  }
}
</script>
<style lang="less" scoped>
/deep/.order_count .ant-card-body {
  padding: 0 24px 3px !important;
  display: flex;
  flex-wrap: wrap;
}
.card_title {
    width: 100%;
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    line-height: 22px;
    display: flex;
}
.card_content {
  width: 100%;
  font-size: 18px;
  line-height: 30px;
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: nowrap;
  margin-top: 4px;
  color: #000;
}
.order_count .card_content {
  width: 50%;
}
/deep/.ant-page-header{
  display: none;
}
</style>

<template>
  <page-header-wrapper class="month-discount" :title='this.$route.meta.title'>
    <a-tabs default-active-key="1" :tabBarStyle="{background: '#fff'}">
      <a-tab-pane key="1" :tab="'获取' + (getTotal > -1 ? '(' + getTotal + ')' : '')">
        <get-list @setGetTitle="setGetTitle" @setUseTitle="setUseTitle"></get-list>
      </a-tab-pane>
      <a-tab-pane key="2" :tab="'使用' + (useTotal > -1 ? '(' + useTotal + ')' : '')">
        <use-list @setGetTitle="setGetTitle" @setUseTitle="setUseTitle"></use-list>
      </a-tab-pane>
    </a-tabs>
  </page-header-wrapper>
</template>

<script>
import GetList from "./GetList"
import UseList from "./UseList"

export default {
  name: 'AuditMonthDiscountList',
  components: {
    GetList, UseList
  },
  data() {
    return {
      getTotal: -1,
      useTotal: -1,
    }
  },
  methods: {
    setGetTitle(value) {
      console.log(value);
      this.getTotal = value
    },
    setUseTitle(value) {
      this.useTotal = value
    }
  },
}
</script>
<style lang="less">
.month-discount {
  .ant-tabs-nav-wrap {
    padding: 0 26px;
  }
  .ant-tabs-bar {
    margin: unset !important;
  }
  >.ant-pro-grid-content>.ant-pro-page-header-wrap-children-content {
    margin: unset !important;
  }
}
</style>
